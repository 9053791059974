import {
  json,
  type LoaderFunctionArgs,
  type MetaFunction,
} from '@remix-run/node'
import { Link } from '@remix-run/react'
import { useTranslation } from 'react-i18next'

import { fallbackLng } from '@biogroup/i18n/config'
import { Button } from '@biogroup/ui/button'

import { GeneralErrorBoundary } from '#app/components/error-boundary.tsx'
import {
  fallbackErrorLabels,
  statusHandlers,
  UnexpectedError,
} from '#app/components/errors.tsx'
import { Layout } from '#app/components/layout.tsx'
import { RootLoaderType } from '#app/root.tsx'
import { requireAnonymous } from '#app/utils/auth.server.ts'
import { i18nServer } from '#app/utils/i18n.server.ts'

export async function loader({ request }: LoaderFunctionArgs) {
  await requireAnonymous(request)
  const t = await i18nServer.getFixedT(request)
  return json({
    meta: {
      title: t('page.home.meta.title'),
    },
  })
}

export const meta: MetaFunction<typeof loader, { root: RootLoaderType }> = ({
  data,
  matches,
}) => {
  const rootData = matches.find(m => m.id === 'root')?.data

  if (data?.meta) {
    return [{ title: data.meta.title }]
  } else {
    return [
      {
        title: fallbackErrorLabels.notFound?.[rootData?.locale ?? fallbackLng],
      },
    ]
  }
}

export default function Index() {
  const { t } = useTranslation()
  return (
    <Layout
      showIconLogo
      title={t('page.root.title')}
      subtitle={t('page.root.subtitle')}
    >
      <div className="flex flex-col items-center">
        <p>{t('page.root.help')}</p>
        <Button variant="secondary" asChild className="mt-4 w-full">
          <Link prefetch="intent" className="no-underline" to="/login">
            {t('page.root.login.link')}
          </Link>
        </Button>
      </div>
    </Layout>
  )
}

export function ErrorBoundary() {
  return (
    <GeneralErrorBoundary
      statusHandlers={statusHandlers}
      unexpectedErrorHandler={error => <UnexpectedError error={error} />}
    />
  )
}
